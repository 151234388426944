




















































































































import { InputSetups } from '@/mixins/input-setups'
import ModuleConfigSettings from '../../components/ModuleConfigSettings.vue'
import ModuleConfigSettingsWrapper from '@/components/Modules/components/ModuleConfigSettingsWrapper.vue'
import Placeholders from '@/mixins/placeholders/placeholders'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'
import InactiveKickHandlerModuleBuilder
  from '@/includes/logic/Modules/models/modules/InactiveKickHandlerModule/InactiveKickHandlerModuleBuilder'
import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'

import { MediaEditorMode } from 'piramis-base-components/src/components/NewMessageEditors/types'
import MultiMessageEditorWithMediaInput from 'piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue'
import TimeUnitInput from 'piramis-base-components/src/components/Pi/fields/TimeUnitInput/TimeUnitInput.vue'
import NumberInput from 'piramis-base-components/src/components/Pi/fields/NumberInput/NumberInput.vue'
import { StepUnit } from 'piramis-base-components/src/components/PeriodSimplifier/types'

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({
  components: {
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
    MultiMessageEditorWithMediaInput,
    TimeUnitInput,
    NumberInput
  },
  data() {
    return {
      MediaEditorMode,
      StepUnit
    }
  },
})
export default class InactiveKickHandlerModuleView extends Mixins(InputSetups, Placeholders, TariffsTagsHelper, ModuleBindings) {
  @VModel() module!: InactiveKickHandlerModuleBuilder
}
